'use strict';

document.addEventListener('DOMContentLoaded', function() {

  /**
   * 右クリック、コピーや保存の操作を禁止
   */

  // 右クリック禁止
  document.addEventListener('contextmenu', function(e) { e.preventDefault(); }, false);

  // テキスト選択禁止
  document.addEventListener('selectstart', function(e) { e.preventDefault(); }, false);

  // キーボードショートカット禁止
  // Ctrl+S（保存）, Ctrl+U（ソースコード）, Ctrl+P（印刷）, F12キー（ソースコード）を禁止
  document.addEventListener('keydown', function(e) {
      if (
          (e.ctrlKey && (e.keyCode === 83 || e.keyCode === 85 || e.keyCode === 80)) ||
          (e.keyCode === 123)
      ) {
          e.preventDefault();
      }
  }, false);

  // ドラッグ禁止
  document.addEventListener('dragstart', function(e) { e.preventDefault(); }, false);

  // コピー禁止
  document.addEventListener('copy', function(e) { e.preventDefault(); }, false);

  // 画像のドラッグ禁止
  document.addEventListener('mousedown', function(e) {
      if(e.target.tagName === 'IMG') {
          e.preventDefault();
      }
  }, false);

  // テキストのユーザー選択を無効化
  document.body.style.userSelect = 'none';
  document.body.style.webkitUserSelect = 'none';
  document.body.style.msUserSelect = 'none';
  document.body.style.mozUserSelect = 'none';


  const body = document.querySelector('body');
  if (body.classList.contains('is-top')) {

    // .index-mainvisual_video と .l-header の要素を取得
    const mainVisualVideo = document.querySelector('.index-mainvisual_video');
    const header = document.querySelector('.l-header');

    if (mainVisualVideo && header) {
      // 高さを取得
      const mainVisualHeight = mainVisualVideo.offsetHeight;
      const headerHeight = header.offsetHeight;
      const totalHeight = mainVisualHeight - headerHeight;

      // スクロールイベントリスナーを追加
      window.addEventListener('scroll', function() {
        const scrollTop = window.scrollY;

        // スクロール位置が指定の高さを超えたら body に .is-scrolled を追加
        if (scrollTop >= totalHeight) {
          body.classList.add('is-scrolled');
        } else {
          body.classList.remove('is-scrolled');
        }
      });
    }
  }

  if ( $('.m-column_nav').length ) {
    $('.m-column_nav_item.is-current').find('.m-column_navChild').addClass('is-open');
  }


  /**
   * パラメータ取得関数
   * URLのクエリ文字列から指定されたパラメータ名に対応する値を取得します。
   * @param {string} name 取得したいパラメータ名
   * @param {string} url 別ページのURLを使用したい場合
   * @returns {string|null} パラメータの値、またはパラメータが存在しない場合はnull
   */
  function getParam(name, url = window.location.search) {
    const params = new URLSearchParams(url);
    const value = params.get(name);
    return value ? sanitizeUrl(value) : null;
  }


  /**
   * エスケープ関数
   * HTMLエスケープ処理を行うための関数です。
   * @param {string} input エスケープしたい文字列
   * @returns {string} エスケープ処理後の文字列
   */
  function sanitizeUrl(input) {
    if (typeof input !== 'string') {
      throw new TypeError('Expected a string');
    }
    return input.replace(/[&'"<>]/g, function(match) {
      return {
        '&': '&amp;',
        "'": '&#x27;',
        '"': '&quot;',
        '<': '&lt;',
        '>': '&gt;',
      }[match];
    });
  }


  /**
   * アンカースクロール
   * @variable headerElement 固定ヘッダーセレクタ
   * @variable headerElement スクロール速度
   */
  const headerClass = '.l-header';

  let scroll = new SmoothScroll('a[href*="#"]', {
    speedAsDuration: true,
    speed: 500,
    header: headerClass,
    easing: 'easeInOutQuint'
  });

  // ページ表示時のアンカー位置にヘッダーの高さを考慮する
  const headerElement = document.querySelector(headerClass);
  let headerElementHeight = 0;

  // ロード時にヘッダーが存在していれば高さを取得
  if ( headerElement !== null ) {
    headerElementHeight = headerElement.offsetHeight;
  }
  // ハッシュ値があればロード時に高さを考慮した位置へスクロール位置を差し替え
  if ( window.location.hash !== '' ) {
    window.addEventListener('load', function() {
      const target = document.querySelector(window.location.hash);
      if ( target !== null ) {
        const position = target.offsetTop - headerElementHeight;
        document.documentElement.scrollTop = position;
      }
    });
  }


  /**
   * ハンバーガーメニュー開閉
   * @variable lGlobalNavButton 固定ヘッダーセレクタ
   */

  const $lGlobalNavButton = $('.l-globalNavButton');
  const $lGlobalNavContainer = $('.l-globalNavContainer');
  let lGlobalNavariaExpanded;

  function togglelGlobalNav() {
    $lGlobalNavButton.toggleClass('is-open');
    $lGlobalNavContainer.fadeToggle();

    lGlobalNavariaExpanded = $lGlobalNavButton.attr('aria-expanded') === 'true' ? 'false' : 'true';
    $lGlobalNavButton.attr('aria-expanded', lGlobalNavariaExpanded);
    $lGlobalNavContainer.attr('aria-hidden', lGlobalNavariaExpanded === 'true' ? 'false' : 'true');
  }

  $lGlobalNavButton.on('click', function() {
    togglelGlobalNav();
  });

  $lGlobalNavContainer.on('keydown', function(e) {
    if (e.key === 'Tab') {
      const focusableElements = $lGlobalNavContainer.find('a, button').filter(':visible');
      const lastElement = focusableElements.last()[0];

      if (e.shiftKey) { // Shift + Tab
        if (document.activeElement === $lGlobalNavButton) {
          e.preventDefault();
          lastElement.focus();
        }
      } else { // Tab
        if (document.activeElement === lastElement) {
          e.preventDefault();
          $lGlobalNavButton.focus();
        }
      }
    }

    if (e.key === 'Escape') {
      togglelGlobalNav();
    }

  });

  $lGlobalNavButton.on('keydown', function(e) {
    const focusableElements = $lGlobalNavContainer.find('a, button').filter(':visible');
    const lastElement = focusableElements.last()[0];
    if (e.key === 'Tab') {
      if (e.shiftKey) { // Shift + Tab
        e.preventDefault();
        lastElement.focus();
      }
    }

    if (e.key === 'Escape') {
      togglelGlobalNav();
    }
  });


  /**
   * スカラー共同研究テーマ紹介（/research-grant/joint/）
   */

  let mAccordionAriaExpanded;

  function toggleAccordion(target) {
    target.toggleClass('is-open');
    target.next('.m-accordion_body').slideToggle();

    mAccordionAriaExpanded = target.attr('aria-expanded') === 'true' ? 'false' : 'true';
    target.attr('aria-expanded', mAccordionAriaExpanded);
    target.next('.m-accordion_body').attr('aria-hidden', mAccordionAriaExpanded === 'true' ? 'false' : 'true');
  }

  $('.m-accordion_btn').on('click', function() {
    toggleAccordion($(this));
  });



  /**
   * アクセシビリティを考慮したスライダー
   * https://ja.splidejs.com/
   */
  /*
  <div class="splide js-pickup-slider" aria-label="screen-read-name">
    <div class="splide__track">
      <ul class="splide__list">
        <li class="splide__slide"></li>
      </ul>
    </div>
    <div class="splide__arrows">
      <button class="splide__arrow splide__arrow--prev">Prev</button>
      <ul class="splide__pagination"></ul>
      <button class="splide__arrow splide__arrow--next">Next</button>
    </div>
  </div>
  */
  if ( document.querySelector('.js-pickup-slider') !== null ) {
    let jsSlider = new Splide('.js-pickup-slider', {
      perPage       : 1,      // 1ページの表示数
      perMove       : 1,      // 1度にスライドを何枚移動するか
      speed         : 800,    // 移動時間（ミリ秒単位）
      type          : 'loop', // ループする場合
      interval      : 4000,   // 自動再生の間隔（ミリ秒単位）
      pauseOnHover  : false,  // マウスホバー時に自動再生をストップさせない
      resetProgress : false,  // 自動再生ストップ時、経過時間を破棄せず続行する
      updateOnMove  : true,   // is-activeクラスを移動前に更新する
      gap           : '20px', // スライド間の余白（cssと同様）
      padding       : '0', // 左右の見切れる幅（cssと同様）
      easing        : 'cubic-bezier(0.25, 1, 0.5, 1)', // 移動アニメーション
      focus         : 'center',

      // インビュー時から自動再生をスタート（拡張機能：splide-extension-intersection.js）
      // autoplay: 'pause',
      // intersection: {
      //   inView: {
      //     autoplay: true,
      //   },
      //   outView: {
      //     autoplay: false,
      //   },
      //   once: true, // 一度画面に表示されたら監視を停止する
      // },

      // 動き続けるスライダーを実装（拡張機能：splide-extension-auto-scroll.js）
      // autoScroll: {
      //   speed: 1, // 負の値で流れる向きが右へ変更
      //   pauseOnHover: false,
      //   pauseOnFocus: false,
      // },

      // メディアクエリ設定（初期：751px以上の場合、スライダーを動作させない）
      mediaQuery: 'min',
      breakpoints: {
        781: {
          destroy: true,
        },
      },
    });

    // スライダーに過不足がある場合にスライダーUIを停止・排除する
    jsSlider.on('overflow', function ( isOverflow ) {
      jsSlider.go(0);
      jsSlider.options = {
        arrows    : isOverflow,
        pagination: isOverflow,
        drag      : isOverflow,
        clones    : isOverflow ? undefined : 0,
      };
    } );

    // スライダーを実行
    jsSlider.mount();
    // jsSlider.mount(window.splide.Extensions);

  }




  if ( document.querySelector('.m-loopAutoSlider') !== null ) {
    let loopAutoSlider = new Splide('.m-loopAutoSlider', {
      arrows        : false,
      pagination    : false,
      drag          : false,
      type          : 'loop', // ループする場合
      pauseOnHover  : false,  // マウスホバー時に自動再生をストップさせない
      resetProgress : false,  // 自動再生ストップ時、経過時間を破棄せず続行する
      gap           : '0', // スライド間の余白（cssと同様）
      padding       : '0', // 左右の見切れる幅（cssと同様）
      easing        : 'cubic-bezier(0.25, 1, 0.5, 1)', // 移動アニメーション
      perPage       : 4,
      // drag:true,
      // インビュー時から自動再生をスタート（拡張機能：splide-extension-intersection.js）

      // 動き続けるスライダーを実装（拡張機能：splide-extension-auto-scroll.js）
      autoScroll: {
        pauseOnHover: false,
        pauseOnFocus: false,
      },

    });

    // スライダーを実行
    loopAutoSlider.mount(window.splide.Extensions);

  }

  function loadJsonAndDisplayImage(element) {
    const jsonPath = element.dataset.jsonImg;
    fetch(jsonPath)
      .then(response => response.json())
      .then(jsonData => {
        // displaySplitImage(element, jsonData);
        displayImage(element, jsonData);
      })
      .catch(error => {
        console.error('JSONの読み込み中にエラーが発生しました:', error);
        element.textContent = '画像の表示に失敗しました';
      });
  }

  function displaySplitImage(element, jsonData) {
    const containerWidth = jsonData.w;
    const containerHeight = jsonData.h;
    const splitSize = 100; // 分割サイズ（ピクセル）
    const wrapper = document.createElement('div');
    wrapper.className = 'm-canvas-wrapper';
    wrapper.style.paddingBottom = `${(containerHeight / containerWidth) * 100}%`;
    element.appendChild(wrapper);
    const skipFactor = Math.sqrt(jsonData.w * jsonData.h / jsonData.p.length);
    for (let y = 0; y < containerHeight; y += splitSize) {
      for (let x = 0; x < containerWidth; x += splitSize) {
        const canvas = document.createElement('canvas');
        canvas.className = 'm-canvas-piece';
        const ctx = canvas.getContext('2d');
        const pieceWidth = Math.min(splitSize, containerWidth - x);
        const pieceHeight = Math.min(splitSize, containerHeight - y);
        canvas.width = pieceWidth;
        canvas.height = pieceHeight;
        canvas.style.left = `${(x / containerWidth) * 100}%`;
        canvas.style.top = `${(y / containerHeight) * 100}%`;
        canvas.style.width = `${(pieceWidth / containerWidth) * 100}%`;
        canvas.style.height = `${(pieceHeight / containerHeight) * 100}%`;
        const imageData = ctx.createImageData(pieceWidth, pieceHeight);
        for (let py = 0; py < pieceHeight; py += skipFactor) {
          for (let px = 0; px < pieceWidth; px += skipFactor) {
            const sourceX = x + px;
            const sourceY = y + py;
            const pixelIndex = Math.floor(sourceY / skipFactor) * Math.floor(containerWidth / skipFactor) + Math.floor(sourceX / skipFactor);
            const pixel = jsonData.p[pixelIndex];
            if (pixel) {
              for (let dy = 0; dy < skipFactor && py + dy < pieceHeight; dy++) {
                for (let dx = 0; dx < skipFactor && px + dx < pieceWidth; dx++) {
                  const i = ((py + dy) * pieceWidth + (px + dx)) * 4;
                  imageData.data[i] = pixel[0];     // R
                  imageData.data[i + 1] = pixel[1]; // G
                  imageData.data[i + 2] = pixel[2]; // B
                  imageData.data[i + 3] = pixel[3]; // A
                }
              }
            }
          }
        }
        ctx.putImageData(imageData, 0, 0);
        wrapper.appendChild(canvas);
        // 右クリック禁止
        canvas.addEventListener('contextmenu', function(event) {
            event.preventDefault();
        });
      }
    }
  }

  function displayImage(element, jsonData) {
    const containerWidth = jsonData.w;
    const containerHeight = jsonData.h;
    const wrapper = document.createElement('div');
    wrapper.className = 'm-canvas-wrapper';
    wrapper.style.paddingBottom = `${(containerHeight / containerWidth) * 100}%`;
    element.appendChild(wrapper);

    // 1つのキャンバスを作成
    const canvas = document.createElement('canvas');
    canvas.className = 'm-canvas-piece';  // クラス名を変更しない
    const ctx = canvas.getContext('2d');
    canvas.width = containerWidth;
    canvas.height = containerHeight;
    canvas.style.width = '100%';
    canvas.style.height = '100%';

    const skipFactor = Math.sqrt(jsonData.w * jsonData.h / jsonData.p.length);
    const imageData = ctx.createImageData(containerWidth, containerHeight);

    for (let y = 0; y < containerHeight; y += skipFactor) {
        for (let x = 0; x < containerWidth; x += skipFactor) {
            const pixelIndex = Math.floor(y / skipFactor) * Math.floor(containerWidth / skipFactor) + Math.floor(x / skipFactor);
            const pixel = jsonData.p[pixelIndex];
            if (pixel) {
                for (let dy = 0; dy < skipFactor && y + dy < containerHeight; dy++) {
                    for (let dx = 0; dx < skipFactor && x + dx < containerWidth; dx++) {
                        const i = ((y + dy) * containerWidth + (x + dx)) * 4;
                        imageData.data[i] = pixel[0];     // R
                        imageData.data[i + 1] = pixel[1]; // G
                        imageData.data[i + 2] = pixel[2]; // B
                        imageData.data[i + 3] = pixel[3]; // A
                    }
                }
            }
        }
    }

    ctx.putImageData(imageData, 0, 0);
    wrapper.appendChild(canvas);

    // 右クリック禁止
    canvas.addEventListener('contextmenu', function(event) {
        event.preventDefault();
    });
}

  function initializeImages() {
    const elements = document.querySelectorAll('[data-json-img]');
    elements.forEach(element => {
      try {
        loadJsonAndDisplayImage(element);
      } catch (error) {
        console.error('画像の表示中にエラーが発生しました:', error);
        element.textContent = '画像の表示に失敗しました';
      }
    });
  }

  window.addEventListener('DOMContentLoaded', initializeImages);


});



/**
   * Researcher Stories
*/
$(function() {
  const $scrollTopBtn = $('.researcherstories_scrolltop');
  $(window).on('scroll', function() {
  // Y軸方向のスクロール量が指定値を超えたらボタンを表示、超えていなければ非表示
  if (window.scrollY > 400) {
    $scrollTopBtn.css({'opacity':1,'pointer-events':'auto'});
  } else {
    $scrollTopBtn.css({'opacity':0,'pointer-events':'none'});
  }
  });
});
